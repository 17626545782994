




































































































































































































































































































































.width_w {
  width: 284px;
}

.tjzs {
  display: flex;
  align-items: center;

  /deep/ .el-form-item__content {
    margin-left: 0 !important;
  }
}
